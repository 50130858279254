import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

interface RegistryPageProps {
  data: {
    file: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

const RegistryPage = (props: RegistryPageProps) => (
  <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
    <Img
      fluid={props.data.file.childImageSharp.fluid}
      alt=""
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#D3D9DD",
        flex: "40%",
      }}
    />
    <div style={{ padding: "20px", flex: "60%" }}>
      <h3
        style={{
          fontFamily: "Raleway",
          fontSize: "16pt",
          marginTop: "20px",
          textAlign: "center",
          fontWeight: 200,
        }}
      >
        The greatest gift of all is your presence on our special day. However,
        if you would still like to honour us with a gift, a contribution towards
        our honeymoon would be greatly appreciated.
      </h3>
    </div>
  </div>
);

export const pageQuery = graphql`
  query RegistryQuery {
    file: file(relativePath: { eq: "rings.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default RegistryPage;
